// Generated by Framer (91d32d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["JtZVOb9Zt", "CtjLA7BZL"];

const serializationHash = "framer-t28nY"

const variantClassNames = {CtjLA7BZL: "framer-v-1ka4rec", JtZVOb9Zt: "framer-v-1xdmd8l"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "JtZVOb9Zt", "Variant 2": "CtjLA7BZL"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, Cmx3a6nXa: title ?? props.Cmx3a6nXa ?? "Let’s Work Together", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "JtZVOb9Zt"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Cmx3a6nXa, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "JtZVOb9Zt", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter2nowk = activeVariantCallback(async (...args) => {
setVariant("CtjLA7BZL")
})

const onMouseLeaverf6a0x = activeVariantCallback(async (...args) => {
setVariant("JtZVOb9Zt")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1xdmd8l", className, classNames)} data-framer-name={"Variant 1"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"JtZVOb9Zt"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnter2nowk} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-006fdba6-9690-47f6-9eee-17e66a9c00aa, rgb(216, 80, 64))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, boxShadow: "inset 4px 4px 18px 0px rgba(255, 255, 255, 0.2800000011920929), 0px 0px 0px 0px rgb(216, 80, 64)", ...style}} variants={{CtjLA7BZL: {boxShadow: "inset 4px 4px 18px 0px rgba(255, 255, 255, 0.2800000011920929), 0px 0px 20px 6px rgba(216, 80, 64, 0.5)"}}} {...addPropertyOverrides({CtjLA7BZL: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeaverf6a0x}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "18px", "--framer-line-height": "150%"}}><motion.span style={{"--font-selector": "R0Y7TWFucm9wZS01MDA=", "--framer-font-family": "\"Manrope\"", "--framer-font-size": "18px", "--framer-font-weight": "500", "--framer-text-color": "var(--extracted-1w3ko1f, rgba(255, 255, 255, 1))"}}>Let’s Work Together</motion.span></motion.p></React.Fragment>} className={"framer-1qlm8j6"} data-framer-name={"Text"} fonts={["GF;Manrope-500"]} layoutDependency={layoutDependency} layoutId={"cBHGPBGmB"} style={{"--extracted-1w3ko1f": "rgba(255, 255, 255, 1)", "--framer-paragraph-spacing": "0px"}} text={Cmx3a6nXa} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-t28nY[data-border=\"true\"]::after, .framer-t28nY [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-t28nY.framer-1uvznel, .framer-t28nY .framer-1uvznel { display: block; }", ".framer-t28nY.framer-1xdmd8l { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 18px 24px 18px 24px; position: relative; width: min-content; }", ".framer-t28nY .framer-1qlm8j6 { flex: none; height: auto; position: relative; white-space: pre; width: auto; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-t28nY.framer-1xdmd8l { gap: 0px; } .framer-t28nY.framer-1xdmd8l > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-t28nY.framer-1xdmd8l > :first-child { margin-left: 0px; } .framer-t28nY.framer-1xdmd8l > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 63
 * @framerIntrinsicWidth 215
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"CtjLA7BZL":{"layout":["auto","auto"]}}}
 * @framerVariables {"Cmx3a6nXa":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framervt9Eg_k4g: React.ComponentType<Props> = withCSS(Component, css, "framer-t28nY") as typeof Component;
export default Framervt9Eg_k4g;

Framervt9Eg_k4g.displayName = "Orange Button";

Framervt9Eg_k4g.defaultProps = {height: 63, width: 215};

addPropertyControls(Framervt9Eg_k4g, {variant: {options: ["JtZVOb9Zt", "CtjLA7BZL"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, Cmx3a6nXa: {defaultValue: "Let’s Work Together", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framervt9Eg_k4g, [{family: "Manrope", style: "normal", url: "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk7PFO_A87jxeN7B.woff2", weight: "500"}])